<template>
<div>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="nombre"
    show-select
    class="elevation-1"
  >

  </v-data-table>     
<br/>
    <v-btn color="success"  small class="mr-4" @click="cargar_preguntas">
      Preguntas para {{$store.state.finca_selected}}
    </v-btn>

   <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
     No ha seleccionado una Finca

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

</div> 


</template>

<script>
  export default {
  props: {
    desserts: [],    
  },    
    data () {
      return {
        singleSelect: true,
        snackbar: false,
        selected: [],
        timeout: 2000,
        headers: [  
          { text: 'Nombre Finca', value: 'nombre', align: 'start' },
          { text: 'Direccion', value: 'direccion' },
          { text: 'Contacto', value: 'nombre_contacto' },
          { text: 'Telefono Contacto', value: 'telefono_contacto' },
          
        ],           
      }
    },
    mounted() {
      this.$store.commit("setFincaSelected", "");
    },
    methods:{
        cargar_preguntas(){
            if(this.selected.length == 0){
                //console.log("vacio")
                this.snackbar = true
            }else{
                let finca = this.selected[0].nombre
                // console.log('selected', this.selected)
                // console.log(finca)
                this.$store.commit("setFincaSelected", finca);
                this.$emit('finca_selected',finca  )
                //console.log('$store.state.finca_selected', this.$store.state.finca_selected)
            }
        }
    }
  }
</script>