<template>
  <v-container class="content-class grey lighten-5">
    <img src="/assets/images/productos.png" width="50" />
    <v-row>
      <v-col v-for="(item, n) in items" :key="n" cols="6" md="4">
        <!-- inicio card -->
        <v-card class="xs-auto sm-auto md-auto lg-auto" max-width="544" shaped>
          <v-carousel
            :continuous="false"
            :cycle="cycle"
            :show-arrows="false"
            delimiter-icon="mdi-minus"
            height="200"
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
              :src="slide.src"
            >
            </v-carousel-item>
          </v-carousel>

          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-h9  mb-4">
                <b>{{ item.nombre }}</b>
              </div>
              <v-list-item-title class="text-h7 mb-1">
                <v-img height="60" :src="`assets/logos/${item.logo}`"></v-img>
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.tipo }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <div class="container d-flex justify-space-between flex-wrap mt-auto mb-auto">
            <!-- boton detalle-->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  large
                  color="teal darken-2"
                  @click="abrirVentana(item)"
                  >mdi-file-eye-outline</v-icon
                >
              </template>
              <span>Detalles</span>
            </v-tooltip>

            <!-- boton de documento -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn :href="item.imagen_detalle" target="_blank"> -->
                <v-btn @click="documentosLista(item, true)">
                  <v-icon v-on="on" v-bind="attrs" large color="teal darken-2"
                    >mdi-file-table-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Ficha Técnica</span>
            </v-tooltip>
            <!-- boton de ficha tecnica -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="documentosLista(item, false)">
                  <v-icon v-on="on" v-bind="attrs" large color="teal darken-2"
                    >mdi-file-cabinet</v-icon>
                </v-btn>
              </template>
              <span>Documentos</span>
            </v-tooltip>
            </div>
          </v-card-actions>
        </v-card>
        <!-- fin  card -->
      </v-col>
    </v-row>
    <!-- ventana emergente  detalle solo texto   -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5 light-green lighten-1">
            <div>Descripción de {{ detalle.nombre }}</div>
          </v-card-title>

          <v-card-text class="text-justify">
            <div class="text-subtitle-2">
              <v-icon>mdi-arrow-right-bold</v-icon>Descripción: <br />
              {{ detalle.descripcion }}
            </div>
            <div class="text-subtitle-2">
              <v-icon>mdi-arrow-right-bold</v-icon>Composición:
              {{ detalle.composicion }}
            </div>
            <div class="text-subtitle-2">
              <v-icon>mdi-arrow-right-bold</v-icon>Tipo: {{ detalle.tipo }}
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
<!-- fin  ventana emergente  detalle solo texto     -->

<!-- ventana emergente  ficha tecnica   -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogficha"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5 light-green lighten-1">
            <div>Descripción de {{ detalle.nombre }}</div>
          </v-card-title>

          <v-card-text class="text-justify"> 
            <v-card-subtitle>
              <!-- ficha tecnica -->              
              <b>Ficha Técnica y Hoja de Seguridad </b> 
            </v-card-subtitle>
            <div v-for="(docs, idp) in arrdocumentos" :key="idp">
                <v-avatar v-if="docs.disabled == false">
                  <img
                  :src="docs.avatar"
                  alt="avatar"
                  >
                </v-avatar>&nbsp;
                <v-btn :href="docs.ruta" target="_blank" :disabled="docs.disabled">                  
                      {{docs.archivo}}
                </v-btn>
                <v-divider inset></v-divider>
            </div>  
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogficha = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- fin  ventana emergente  ficha tec   -->   
<!-- ventana emergente  documentos   -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogdoc"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5 light-green lighten-1">
            <div>Descripción de {{ detalle.nombre }}</div>
          </v-card-title>

          <v-card-text class="text-justify"> 
            <v-card-subtitle>
              <b>{{detalle_doc.tipo}} </b>
                          
            </v-card-subtitle>
            <div v-for="(docs, idp) in arrdocumentos" :key="idp">
                
                <v-btn @click="detalleDocLista(docs)" :disabled="detalle_doc.disabled">                  
                      {{docs.nombre}}
                </v-btn>
                <v-divider inset></v-divider>
            </div>  
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>                 
          
            <v-btn color="green darken-1" text @click="dialogdoc = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- fin  ventana emergente     -->        
<!-- ventana emergente detalles de los  documentos   -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogdetalledoc"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5 light-green lighten-1">
            <div>Descripción de {{ detalle.nombre }}</div>
          </v-card-title>

          <v-card-text class="text-justify"> 
            <v-card-subtitle>
              <b>{{detalle_doc.tipo}} </b>
            </v-card-subtitle>
            <div v-for="(docs, idp) in arrdocumentos" :key="idp">
                
                <v-btn @click="generarLinkDocumento(docs)" :disabled="detalle_doc.disabled">                  
                      {{docs.nombre}}
                </v-btn>
        <!-- ################################        email button  #################     -->
          <v-btn color="green darken-1" text @click="emailSend(docs)" v-if="show_email" :disabled="detalle_doc.disabled">
              <v-icon  large color="teal darken-2">mdi-email</v-icon> 
          </v-btn> 

                <v-divider inset></v-divider>
            </div>  
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer> 

            <v-btn color="green darken-1" text @click="dialogdetalledoc = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>    
    <!-- fin  ventana emergente     -->        
  </v-container>
</template>

<script>
//import ventanaemergente from '@/components/ventanaemergente.vue';
import ProductService from "@/services/storagefbclass";
export default {
  name: "listaProductos",
  //  components: { ventanaemergente },
  props: {
    items: Array,
  },
  data: () => ({
    detalle: {},
    detalle_doc: {
      archivo: '',
      ruta: '',
      tipo: '',
      avatar: '',
      nombre: '',
      disabled: false
    },
    arrdocumentos: [],
    dialog: false,
    show_email:false,
    dialogdoc:false,    
    dialogdetalledoc:false,    
    dialogficha:false,
    result_link: "",
    obj_seleccionado: "",
    reveal: false,
    cycle: false,
    slides: [],
    slides2: [],
    slides_base: [
      {
        src:
          "/assets/images/prod_sliders/slider1.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider2.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider3.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider4.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider5.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider6.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider7.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider8.JPG",
      },
      {
        src:
          "/assets/images/prod_sliders/slider9.JPG",
      },
    ],
  }),
  created() {
    //console.log(`items`, this.items)
    // const x = new ProductService()
    // const salida = x.listaPaginada("administracion/slider/actara")
    // console.log(`object`, salida)
  this.slides2 = []
  this.slides2 = this.slides_base
  let obj_random = {}
  let random = 0
  for (let index = 0; index < 10; index++) {    
    random = Math.floor(Math.random() * this.slides2.length)
    obj_random = {
      src: this.slides2[random],
    } 

    //console.log('obj_random__', obj_random);
    this.slides.push(obj_random)
    obj_random = {}
    this.slides2.splice(random, 1);

    if(this.slides.length == 4){
      break;
    }
  
  }
    

  },

  methods: {
    abrirVentana(datos) {
      this.detalle = datos;
      this.dialog = true;
    },
    emailSend(doc){
      
      const strfb      = new ProductService();
      let   path_file  = doc.ruta
      var   name_obj   = doc.nombre.slice(0,-4)     
      

      // console.log('doc___', doc);  return false;
      // console.log('name_obj', name_obj);
      // console.log('path_file', path_file);     
      
      strfb.listaImages(path_file).then((result_link) => {   
        //console.log('this.name_obj', name_obj);

          // console.log('result_link', result_link);              
          // let body = encodeURIComponent(`Documento: <a href="${result_link}">${doc.nombre}</a>`)      
          let subj = `Informacion de ${this.obj_seleccionado}`
          let body = escape(`Te adjuntamos la Hoja de seguridad y/o Ficha Tecnica de ${name_obj} : \n ${result_link} \n`)
          // console.log('bosy', body);          
          window.open(`mailto:helberth@localhost.com?subject=${subj}&body=${body}`);
          this.dialogdoc        = false                     
          this.dialogdetalledoc = false            
      }).catch((err) => {
        console.log(`err`, err)
      }); 

    },
    fichaTecnica(item) {     
      this.detalle             = item;
      const strfb              = new ProductService();
      let   path_file          = ""
      this.arrdocumentos       = []
      this.detalle_doc         = {}
      let path_folder        = `productos/Colombia/${item.folder}/Ficha tecnica`
      
      //console.log('__item', item);
      

      strfb.listadocPaginada(path_folder).then((result) => {               
          //console.log('result_ficha', result);
          if(result.items.length != 0){                    
                path_file = result.items[0]._location.path
                let name_file = path_file.slice((path_folder.length+1))                
                strfb.listaImages(path_file).then((result) => {              
                    this.detalle_doc.disabled = false
                    this.detalle_doc.archivo  = name_file
                    this.detalle_doc.ruta     = result
                    this.detalle_doc.nombre   = name_file.slice(0,-4)
                    this.detalle_doc.avatar = `https://ui-avatars.com/api/?name=${this.detalle_doc.nombre}&background=0D8ABC&color=fff`
                    this.detalle_doc.tipo     = "Ficha Técnica"                    
                    //  console.log(`detalle_doc_ficha_`, this.detalle_doc)
                    this.arrdocumentos.push(this.detalle_doc)
                }).catch((err) => {
                  console.log(`err`, err)
                });
            
          }else{            
            this.detalle_doc.archivo  = "No hay Documento Disponible"     
            this.detalle_doc.disabled = true                        
            this.detalle_doc.ruta     = ""
            this.detalle_doc.nombre   = ""
            this.detalle_doc.avatar   = ""
            this.detalle_doc.tipo     = "Ficha Técnica"   
            this.arrdocumentos.push(this.detalle_doc)         
          }

          
          // console.log('this.arrdocumentos_ficha', this.arrdocumentos);
          this.dialogdoc   = false  
          this.dialogdetalledoc = false
          this.dialogficha = true

      }).catch((err) => {
        console.log(`err`, err)
      });
    },

    documentosLista(item, ficha = true){
      /**
       * esta funcion muestra las carpetas q tiene un producto
       */
 if (ficha == true) {
    this.show_email = true
 }else{
    this.show_email = false
 }      
      this.detalle   = item;
      let path_folder = ""
      path_folder = `productos/Colombia/${item.folder}/`
      this.arrdocumentos = []      
      this.detalle_doc   = {}

      //console.log('__item', item);
      
      // console.log('ficha', ficha);
      const strfb = new ProductService();

      strfb.ListarTodo(path_folder).then((result) => {
         /**
          * caso para ficha tecnica y hoja de seguridad
          */
          if (ficha == true) {
              
              result.prefixes.forEach((folderRef) => {
                  if (folderRef.name == "Ficha tecnica" || folderRef.name == "Hoja de seguridad") {
                   // console.log(`folderRef_carpetas`, folderRef)              
                    this.detalle_doc.nombre   = folderRef.name
                    this.detalle_doc.disabled = false
                    this.detalle_doc.archivo  = folderRef.parent.name
                    this.detalle_doc.ruta     = folderRef.fullPath
                    this.detalle_doc.avatar   =  ""
                    this.detalle_doc.tipo     = "Documentos"  
                    this.arrdocumentos.push(this.detalle_doc) 
                  // console.log(`this.detalle_doc`, this.detalle_doc)
                    this.detalle_doc   = {}                        
                  }
              });            
          }else{            
            result.prefixes.forEach((folderRef) => {           
                  //console.log(`folderRef_carpetas`, folderRef)              
                  this.detalle_doc.nombre   = folderRef.name
                  this.detalle_doc.disabled = false
                  this.detalle_doc.archivo  = folderRef.parent.name
                  this.detalle_doc.ruta     = folderRef.fullPath
                  this.detalle_doc.avatar   =  ""
                  this.detalle_doc.tipo     = "Documentos"  
                  this.arrdocumentos.push(this.detalle_doc) 
                // console.log(`this.detalle_doc`, this.detalle_doc)
                  this.detalle_doc   = {}                       
               });

          }
                //console.log('this.arrdocumentos', this.arrdocumentos);

      }).catch((err) => {
        console.log(`err`, err)
      }); 
      
          // console.log(`this.arrdocumentos`, this.arrdocumentos)
          this.dialogdoc = true  
    },
    detalleDocLista(docs){
      /**
       * esta funcion muestra la carpeta escogida que archivos tiene
       */
      
      //console.log('__docs', docs);
      this.obj_seleccionado  =  docs.nombre
      let path_folder        = docs.ruta
      // let   path_file        = ""
      const strfb            = new ProductService();
      this.detalle_doc       = []
      
            
      strfb.listadocPaginada(path_folder).then((result) => {

        this.arrdocumentos     = []      
          if(result.items.length != 0){   
            result.items.forEach(element => {
              //console.log('element', element);                
              this.detalle_doc.archivo  = element.name     
              this.detalle_doc.disabled = false                        
              this.detalle_doc.ruta     = element._location.path
              this.detalle_doc.nombre   = element.name
              this.detalle_doc.avatar   = ""
              this.detalle_doc.tipo     = docs.tipo
              this.arrdocumentos.push(this.detalle_doc) 
              this.detalle_doc   = {} 
              this.detalle_doc.tipo     = docs.nombre
            });               
            
          }else{
            this.detalle_doc.archivo  = "No hay Documento Disponible"     
            this.detalle_doc.disabled = true                        
            this.detalle_doc.ruta     = ""
            this.detalle_doc.nombre   = "No hay Documento Disponible"     
            this.detalle_doc.avatar   = ""
            this.detalle_doc.tipo     = docs.tipo
            this.arrdocumentos.push(this.detalle_doc)            
          }

          // console.log('this.arrdocumentos_lista', this.arrdocumentos);
          this.dialogdoc        = false                      
          this.dialogdetalledoc = true  

      }).catch((err) => {
        console.log(`err`, err)
      });        

    },
     generarLinkDocumento(doc){
      //console.log('doc', doc);
      const strfb      = new ProductService();
      let   path_file  = doc.ruta
      strfb.listaImages(path_file).then((result_link) => {              
        // console.log('result_link', result_link);    
          window.open(result_link, '_blank').focus();                           
          this.dialogdoc        = false                     
          this.dialogdetalledoc = false            
      }).catch((err) => {
        console.log(`err`, err)
      });      

    },
  },
};
</script>

<style lang="scss" scoped></style>
