<template>

  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="nombre"
    class="elevation-1"
    :loading="loadingajax"
    :loading-text="mensaje_loading"
  >
      <v-progress-linear
        :active="loadingajax"
        :indeterminate="loadingajax"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>  
  
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Lista</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
<!-- ############  card de editar datos y crear datos ############# -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" lg="12">
                    <!-- cedula -->
                    <v-text-field
                      v-model="editedItem.cedula"
                      label="Cedula"
                      :counter="10"
                      required
                    ></v-text-field>                    
                    <!-- nombre -->
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre"
                      :counter="10"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <!-- apellido -->
                    <v-text-field
                      v-model="editedItem.apellidos"
                      label="Apellidos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <!-- email -->
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <!-- passwd -->
                    <v-text-field
                      v-model="editedItem.passwd"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Contraseña"
                      hint="Mínimo 8 caracteres"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <!-- movil -->
                    <v-text-field
                      v-model="editedItem.movil"
                      label="Movil"
                    ></v-text-field>
                    <!-- uid -->
                    <v-text-field
                      v-model="editedItem.uid"
                      label="uid"
                      class="d-none"
                    ></v-text-field>                    
                  </v-col>
                  <v-col cols="12" lg="6">
                    <!-- perfil -->                    
                      <v-select
                        :items="items_perfil"
                        v-model="editedItem.perfil"
                        label="Perfil"
                        item-value="abr"
                        item-text="label"          
                      ></v-select>                    
                    <!-- activo -->
                    <v-checkbox
                    v-model="editedItem.activo"
                    :label="`Activo:`"
                    ></v-checkbox>                    
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
<!-- ############  card botones ############# -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import ManageUser from "@/services/UsersServices";

export default {
  name: "crudusuario",

  data: () => ({
    dialog: false,
    dialogDelete: false,
    mensaje_loading: "Cargando Informacion",
    show1: false,
    search: "",
    loadingajax: false,
    items_perfil: [
          { label: 'Administrador', abbr: 'Admin' },
          { label: 'Documentador', abbr: 'Doc' },
          { label: 'Usuario Técnico', abbr: 'Tecnico' },
        ],    
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      { text: "Apellidos", value: "apellidos" },
      { text: "Email", value: "email" },
      { text: "Movil", value: "movil" },
      { text: "Perfil", value: "perfil" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      apellidos: "",
      email: "",
      movil: "",
      perfil: "",
      passwd: "",
      activo: true,
      cedula: "",
      uid: ""
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Usuario"
        : "Editar Usuario Actual";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
      initialize() {
        this.loadingajax = true
        const manage_user = new ManageUser();
        /*manage_user.getDetalleLogin('79956602')
        .then((doc) => {
          console.log('doc :>> ', doc);
        }).catch((err) => {
          console.log('err :>> ', err);
        });*/
        manage_user.listaUsuarios()        
        .then((data) => {          
          this.loadingajax = false
          if(data.length == "undefined"){            
            this.mensaje_loading = "No hay datos para mostrar"
          }else{
            data.forEach((doc) => {
              //console.log('doc :>> ', doc);
             this.desserts.push(doc.data())
            });            
          }

        }).catch((err) => {
          this.loadingajax = false
          console.log('err :>> ', err);
        });

    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const manage_user = new ManageUser();
      //console.log(`this.editedItem.uid`, this.editedItem.uid)
      manage_user.eliminar(this.editedItem.uid)      
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {      
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      const manage_user = new ManageUser();
      this.loadingajax = true
      if (this.editedIndex > -1) {
      /** caso editar **/
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        //console.log(`this.editedItem.cedula`, this.editedItem)
        manage_user.actualizar(this.editedItem.uid, this.editedItem)
      } else {
        /** caso nuevo**/
        this.desserts.push(this.editedItem);        
        manage_user.Creacion(this.editedItem.email, this.editedItem.passwd, this.editedItem.uid, this.editedItem)
          .then((userCredential) => {
            userCredential.user;
           // console.log("salida :>> ", salida);
          })
          .catch((error) => {
            //const errorCode = error.code;
            //const errorMessage = error.message;
            const errores = `Error en el proceso codigo ${error.code} mensaje ${error.message}`;
            console.log("errores :>> ", errores);
            // ..
          });
      }
      this.loadingajax = false
      this.close();
    },
  },
};
</script>
