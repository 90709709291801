<template>
  <div class="wrapper2 fadeInDown">
    <div id="formContent">
      <div class="fadeIn first" :style="{ padding: '29px' }">
        Bienvenido
      </div>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            v-model="passwd"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Contraseña"
            hint="Mínimo 8 caracteres"
            required
            :rules="passRules"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn class="mr-4" @click="validate">
            Entrar
          </v-btn>
          <v-btn @click="reset">
            Limpiar
          </v-btn>
        </v-form>
      </v-container>

      <div id="formFooter">
        <a class="underlineHover" href="#">Forgot Password?</a>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import ManageUser from "@/services/UsersServices";
export default {
  data: () => ({
    valid: true,
    passwd: "",
    email: "",
    show1: false,
    passRules: [
      (v) => !!v || "Contraseña es requerido",
      (v) => (v && v.length >= 8) || "La clave debe ser minimo de 8 caracteres",
    ],
    emailRules: [
      (v) => !!v || "E-mail es requerido",
      (v) => /.+@.+\..+/.test(v) || "E-mail no es valida la estructura",
    ],
  }),
  created() {
    if (this.$route.params.model === "logout") {
      this.logout_submit();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.login_submit();
      }
    },
    reset() {
      this.$refs.form.reset();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    login_submit() {
      const manage_user = new ManageUser();
      manage_user
        .Login(this.email.trim(), this.passwd)
        .then((userCredential) => {
          const salida = userCredential.user;
          //console.log("salida :>> ", salida);
          sessionStorage.setItem("key", salida.uid);
          sessionStorage.setItem("token", salida.accessToken);
          this.$store.commit("setLoginUser");
          
          manage_user.getDetalleLogin(salida.uid)
          .then((result) => {
            //console.log(`result`, result)  
            this.$store.commit("setCurrentUser", result);
          }).catch((err) => {
            console.log(`err`, err)            
          });


          this.$router.push({ name: "Home" });

          // console.log('object :>> ', object);
          
        })
        .catch((error) => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          const errores = `Error en el proceso codigo ${error.code} mensaje ${error.message}`;
          console.log(errores);
          this.$swal("", "Usuario/Clave incorrecta", "error");
          // ..
        });
    },
    logout_submit() {
      const manage_user = new ManageUser();
      manage_user.Logout();
      this.$store.commit("setLogout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style>
/* STRUCTURE */

.wrapper2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 10px;
  width: 60%;
  max-width: 850px;
  position: relative;
  padding: 0px;
  /* -webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 15px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
</style>
