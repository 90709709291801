<template>
  <v-container class="content-class grey lighten-5">
            <img src="/assets/images/2plaga.png" width="50" /> 
    <v-row>
      <v-col
        v-for="item,n in items"
        :key="n"
        cols="6"
        md="4"
      >
 <!-- inicio card -->
<v-card
    class="mx-auto"
    max-width="344"    
    shaped
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-h9  mb-4">
         {{item.nombre}}
        </div>
        <v-list-item-title class="text-h7 mb-1">         
          <v-img  max-height="103" :src="`assets/images/${item.fotos[0]}`" @click="full_Image(item.fotos[0])" ></v-img>             
          
        </v-list-item-title>
        <v-list-item-subtitle>{{item.tipo}}</v-list-item-subtitle>
      </v-list-item-content>


    </v-list-item>
   

    <v-card-actions>
      <div class="content-class">
      <v-btn
        outlined
        rounded
        text
        small
         @click="abrir_ventana(item)"
      >
        Detalle
      </v-btn>
      </div>
    </v-card-actions>
  </v-card> 
 <!-- fin  card -->
      </v-col>    
    </v-row>
<!-- ventana emergente     -->
  <v-row justify="center">

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
            <v-toolbar
              color="primary"
              dark
            >{{detalle.nombre}}</v-toolbar>

        <v-card-text>
          <div class="text-subtitle-2">-Descripción: <br> {{detalle.detalle}} </div>         
             
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>   

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<!-- fin  ventana emergente     -->  

<!-- ventana con detalle de la imagen seleccionada  -->
    <v-overlay
      :z-index="zIndex"
      :value="overlay"
      opacity="1"      
    >
        <v-img   width="600" :src="`assets/images/${full_image}`"></v-img>
      <v-btn
        class="white--text"
        color="teal"
        x-small
        @click="overlay = false"
      >
        Cerrar
      </v-btn>
    </v-overlay>


  </v-container>
</template>

<script>
  export default {
  name: "listaProductos",
  props: {
    items: Array,
  },
  data: () => ({    
    detalle: {},
    dialog:false,
    reveal: false,
    overlay: false,
    zIndex: 10,
    full_image: ""    
  }),    
  methods:{
    abrir_ventana(datos){      
      this.detalle = datos
      this.dialog = true
      
    },
    full_Image(image){
      this.full_image = image
      this.overlay = true
    }
  }  

  }
</script>

<style lang="scss" scoped>

</style>        