<template>
    <div class="container">
        <h6 class="d-flex mx-auto">Registro de Visita</h6>
        <listafincas :desserts="fincas" @finca_selected="finca_seleccionada" />
        <preguntasfincas :finca_selected="finca_select"/>
    </div>
</template>

<script>
import preguntasfincas from "@/components/PreguntasFincas";
import listafincas from "@/components/ListaFincas";
import FincasService from "@/services/FincasService";
    export default {
        components: { preguntasfincas, listafincas },
        name: "preguntas",
        data() {
            return {
                fincas: [],
                finca_select: null
            }
        },
  mounted() {
     this.cargardata()
  },
  methods: {
   async cargardata() {
      let fincasservice = new FincasService();
      let response = await fincasservice.listarFincas("fincas");
      response.forEach((doc) => {
            //console.log("doc", doc.data());
            this.fincas.push(doc)
          });

        //console.log('this.fincas', this.fincas)
    },
    finca_seleccionada(finca){
        //console.log('first', finca)
        this.finca_select = finca
    }
  },        
        
    }
</script>

<style lang="scss" scoped>

</style>