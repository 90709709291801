import { fstorage } from "@/firebase";
import { ref, listAll, getDownloadURL, list } from "firebase/storage";

export default class ProductService {
  ListarTodo(path) {
    // console.log(`path`, path);
    const storage = fstorage;
    const listRef = ref(storage, path);
    return listAll(listRef);
  }

  listadocPaginada(fullpath) {
    const storage = fstorage;
    const listRef = ref(storage, fullpath);
    const firstPage = list(listRef, { maxResults: 80 });
    return firstPage;
  }

  // metodo que trae informacion del link segun la ruta para ver informacion.
  async listaImages(fullpath) {
    const storage = fstorage;
    const gsReference = ref(storage, fullpath);
    const data_url = await getDownloadURL(gsReference);
    //console.log(`data_url`, data_url);
    return data_url;
  }
}
