<template>
  <div class="container">  
    <v-container v-if ="guardado !== true">
<v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="obj_finca.nombre"            
      label="Nombre"
      hint="Nombre de la finca"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.direccion"            
      label="Direccion"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.telefono"            
      label="Telefono"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.nombre_contacto"            
      label="Nombre Contacto"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.documento_contacto"            
      label="Documento Contacto"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.email_contacto"
      :rules="emailRules"
      label="E-mail Contacto"
      required
    ></v-text-field>    

    <v-text-field
      v-model="obj_finca.fecha_nacimiento"            
      label="Fecha Nacimiento"
      required
    ></v-text-field>

    <v-text-field
      v-model="obj_finca.telefono_contacto"            
      label="Telefono Contacto"
      required
    ></v-text-field>

 
        <v-select
        v-model="obj_finca.cultivo1"
        :items="cult_lista"
        label="Cultivos"
        multiple
        chips
        hint="What are the target regions"
        persistent-hint
      ></v-select>


    <v-text-field
      v-model="obj_finca.interes"            
      label="Interes"
      required
    ></v-text-field>

        <v-select
        v-model="obj_finca.productos_vendidos"
        :items="prod_lista"
        label="Productos"
        multiple
        chips
        hint="What are the target regions"
        persistent-hint
      ></v-select>


    <v-text-field
      v-model="obj_finca.tipo_cliente"            
      label="Tipo Cliente"
      required
    ></v-text-field>

    <!-- <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field> -->

 
  

    <v-btn color="success" class="mr-4" @click="guardar">
      Guardar
    </v-btn>



  
  </v-form> 
  </v-container>   
  <v-alert
      dense
      text
      v-if="guardado"
      type="success"
    >
      Información almacenada exitosamente!!
    </v-alert>

  </div>
  
</template>
<script>
import FincasService from "@/services/FincasService";
import ProductService from "@/services/ProductService";
import CultivosService from "@/services/CultivosService";

export default {
  
  name: "RegistroFincas",
  data: () => ({
    valid: true,
    guardado: false,
    name: "",  
    email: "",
    select: null, 
    obj_finca:{
      cultivo1: '',
      cultivo2: '',
      cultivo3: '',
      direccion: '',
      documento_contacto: '',
      email_contacto: '',
      fecha_nacimiento: '',
      interes: '',
      nombre: '',
      nombre_contacto: '',
      productos_vendidos: '',
      telefono: '',
      telefono_contacto: '',
      tipo_cliente: '',      
    },
   prod_lista: [],          
   cult_lista: [],
    emailRules: [
      v => !!v || 'E-mail es requerido',
      v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
    ],             
  }),
  mounted() {
    this.cargar_listas()
  },
  methods: {
    guardar(){
      this.$Progress.start();
       let fincasservice = new FincasService();
        fincasservice.getGreateFincas(this.obj_finca,"fincas")
      //console.log('tih.obj_finca', this.obj_finca)
      this.$Progress.finish();   
      this.guardado = true 
    },
   async cargar_listas(){

      const product_service = new ProductService();
      let response = await product_service.listarProd("productos")
      response.forEach(element => {
        this.prod_lista.push(element.nombre)
      });      

      this.prod_lista.sort()
      response = []
      const cultivo_service = new CultivosService();
      response = await cultivo_service.listarCultiv("cultivos")         
      response.forEach(element => {
        this.cult_lista.push(element.nombre)
      });      
    this.cult_lista.sort();

   } 
    
  },
};
</script>
