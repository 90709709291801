<template>
<div>
    <h3>Administración de productos</h3>
  <v-data-table
    :headers="headers"
    :items="desserts"    
    sort-by="blanco_biologico"
    group-by="blanco_biologico"
    class="elevation-1"    
    :single-select="singleSelect"
    item-key="id"
    show-group-by
    @click:row="select"
  >
  </v-data-table>
</div>
</template>
<script>
  export default {
    data () {
      return {
        singleSelect: true,
        selected: '',          
        headers: [
          {text: 'Producto',align: 'start', value: 'producto', groupable: false },
          { text: 'Blanco Biologico', value: 'blanco_biologico', align: 'right' },
          { text: 'Cultivo', value: 'cultivo', align: 'right' },
        ],
        desserts: [
        {
            "id": "1",
            "producto": "ACTARA 25 WG",
            "blanco_biologico": "AFIDOS",
            "cultivo": "ROSA"
        },
        {
            "id": "2",
            "producto": "ACTARA 25 WG",
            "blanco_biologico": "AFIDOS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "3",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "ACAROS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "4",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "ACAROS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "5",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "ACAROS",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "6",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "TRIPS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "7",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "TRIPS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "8",
            "producto": "ACTELLIC  50 EC",
            "blanco_biologico": "TRIPS",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "9",
            "producto": "ALTIMA 500 SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ROSA"
        },
        {
            "id": "10",
            "producto": "ALTIMA 500 SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "11",
            "producto": "ALTO 100 SL",
            "blanco_biologico": "MANCHA FOLIAR",
            "cultivo": "CLAVEL"
        },
        {
            "id": "12",
            "producto": "AMISTAR 50 WG",
            "blanco_biologico": "MILDEO VELLOSO",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "13",
            "producto": "AMISTAR 50 WG",
            "blanco_biologico": "MANCHA ANILLADA",
            "cultivo": "CLAVEL"
        },
        {
            "id": "14",
            "producto": "AMISTAR 50 WG",
            "blanco_biologico": "ROYA",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "15",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "16",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "17",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "18",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ROSA"
        },
        {
            "id": "19",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "20",
            "producto": "AMISTAR TOP SC",
            "blanco_biologico": "MANCHA ANILLADA",
            "cultivo": "CLAVEL"
        },
        {
            "id": "21",
            "producto": "DACONIL 720 SC",
            "blanco_biologico": "MANCHA FOLIAR",
            "cultivo": "CLAVEL"
        },
        {
            "id": "22",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "23",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "ALSTROEMERIA"
        },
        {
            "id": "24",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "25",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "GERBERA"
        },
        {
            "id": "26",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "ROSA"
        },
        {
            "id": "27",
            "producto": "ENGEO 247 SC",
            "blanco_biologico": "TRIPS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "28",
            "producto": "HICURE",
            "blanco_biologico": "BIOESTIMULANTE",
            "cultivo": "ROSA"
        },
        {
            "id": "29",
            "producto": "KARATE ZEON 5 CS",
            "blanco_biologico": "TRIPS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "30",
            "producto": "KARATE ZEON 5 CS",
            "blanco_biologico": "TRIPS",
            "cultivo": "ROSA"
        },
        {
            "id": "31",
            "producto": "MAINSPRING FLORA",
            "blanco_biologico": "MOSCA BLANCA",
            "cultivo": "ROSA"
        },
        {
            "id": "32",
            "producto": "MAINSPRING FLORA",
            "blanco_biologico": "TRIPS",
            "cultivo": "ROSA"
        },
        {
            "id": "33",
            "producto": "MAINSPRING FLORA",
            "blanco_biologico": "MINADOR",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "34",
            "producto": "MATCH 50 EC",
            "blanco_biologico": "MINADOR",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "35",
            "producto": "MERTECT 500 SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "36",
            "producto": "MERTECT 500 SC",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "CLAVEL"
        },
        {
            "id": "37",
            "producto": "PICATINA FLORA",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ROSA"
        },
        {
            "id": "38",
            "producto": "PICATINA FLORA",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "39",
            "producto": "PLENTRIX",
            "blanco_biologico": "MILDEO VELLOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "40",
            "producto": "POLO 500 SC",
            "blanco_biologico": "ACAROS",
            "cultivo": "ROSA"
        },
        {
            "id": "41",
            "producto": "REVUS 250 EC",
            "blanco_biologico": "MILDEO VELLOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "42",
            "producto": "RIDOMIL GOLD 68 WP",
            "blanco_biologico": "MILDEO VELLOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "43",
            "producto": "SCORE 250 EC",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "44",
            "producto": "SCORE 250 EC",
            "blanco_biologico": "MANCHA ANILLADA",
            "cultivo": "CLAVEL"
        },
        {
            "id": "45",
            "producto": "SEGOVIS FLORA",
            "blanco_biologico": "MILDEO VELLOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "46",
            "producto": "SOLVIT",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "47",
            "producto": "SOLVIT",
            "blanco_biologico": "MANCHA ANILLADA",
            "cultivo": "CLAVEL"
        },
        {
            "id": "48",
            "producto": "SOLVIT",
            "blanco_biologico": "ROYA",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "49",
            "producto": "SOLVIT",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "50",
            "producto": "SUNJET",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ROSA"
        },
        {
            "id": "51",
            "producto": "SUNJET",
            "blanco_biologico": "MILDEO POLVOSO",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "52",
            "producto": "SUNJET",
            "blanco_biologico": "MANCHA ANILLADA",
            "cultivo": "CLAVEL"
        },
        {
            "id": "53",
            "producto": "SWITCH 62.5 WG",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ROSA"
        },
        {
            "id": "54",
            "producto": "SWITCH 62.5 WG",
            "blanco_biologico": "BOTRYTIS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "55",
            "producto": "TRIGARD 75 WP",
            "blanco_biologico": "MINADOR",
            "cultivo": "ORNAMENTALES"
        },
        {
            "id": "56",
            "producto": "TRIGARD 75 WP",
            "blanco_biologico": "MINADOR",
            "cultivo": "CRISANTEMO"
        },
        {
            "id": "57",
            "producto": "VERTIMEC 018 EC X LT",
            "blanco_biologico": "ACAROS",
            "cultivo": "ROSA"
        },
        {
            "id": "58",
            "producto": "VERTIMEC 018 EC X LT",
            "blanco_biologico": "MINADOR",
            "cultivo": "CRISANTEMO"
        }
    ],
      }
    },
    methods:{
        select(item){
            //this.selected = item.producto
            this.$emit('updatingScore', item.producto)
            //console.log('select :>> ', this.selected);
        }
    }
  }
</script>