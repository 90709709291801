<template>
  <div>    
     <v-btn icon class="float-sm-right" @click="filtrar=true;listar=false">
        <v-icon v-if="listar">mdi-magnify</v-icon>
      </v-btn>    
      <listaProductos :items="items" v-if="listar"/>      
      <filtrosproductos v-if="filtrar" @updatingScore="updateScore"/>
  </div>
</template>


<script>
import ProductService from "@/services/ProductService";
import listaProductos from '@/components/listaProductos';
import filtrosproductos from '@/components/filtros_productos';

export default {
  components: { listaProductos, filtrosproductos },
  name: "productos",
  props: {
    msg: String,
  },
  data: () => ({
    selected: [],
    items: [],
    listar:true,
    filtrar:false,
    detalle: {},
    cargar:false,
    reveal: false,
  }),
  mounted() {
    this.$Progress.start();    
    if(this.$route.params.prod){
      this.busqueda_producto()
    }else{
      this.traerdata();
    }    
    //console.log('items :>> ', this.items);
  }, 
  methods: {    
    traerdata() {
      const product_service = new ProductService();
      product_service.getProducts("productos")
      .then((result) => {        
        this.$Progress.finish();          
        result.forEach((doc) => {          
          this.items.push(doc.data())
          //console.log(doc.data());
          //console.log('doc.id :>> ', doc.id);
        });

      })
      .catch((err) => {
        this.$Progress.fail();
        console.log("object :>> ", err);
      })  

    },
    busqueda_producto(){
      const product_service = new ProductService();
      product_service.getSingleProduct("productos", this.$route.params.prod)
      .then((result) => {        
        this.$Progress.finish();
        this.items = []          
        result.forEach((doc) => {          
          this.items.push(doc.data())
          console.log(this.items);
          //console.log('doc.id :>> ', doc.id);
        });

      })
      .catch((err) => {
        this.$Progress.fail();
        console.log("object :>> ", err);
      })        
      

    },
    async crearproductos() {
      try {       

        // const product_service = new ProductService();         
        // hrc.forEach(element => {
        //      const nombre = element.nombre;
        //      const key = nombre.replace(/(\s)+/gi, "-").toLowerCase();

        //     const prod_detail = {
        //       categoria_toxicologica: element.categoria_toxicologica, 
        //       clasificacion_irac: element.clasificacion_irac, 
        //       composicion: element.composicion,
        //       descripcion: element.descripcion,
        //       formulacion: element.formulacion, 
        //       grupo_quimico: element.grupo_quimico,
        //       imagen_detalle: element.imagen_detalle, 
        //       logo: element.logo, 
        //       nombre: element.nombre,
        //       key: key,
        //       tipo: element.tipo, 
        //     };             
        //     product_service.getCrearProd(prod_detail, key, "productos");
        //     console.log('element.logo :>> ', prod_detail );            
        // });


        // product_service.getCrearProd(prod, key);
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    updateScore(newValue){      
      this.selected = this.items.find( prod => prod.nombre === newValue );
      this.items = []
      this.items.push(this.selected)
      this.listar = true
      this.filtrar = false


    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
