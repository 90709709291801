<template>
  <div class="container">
    <h6 class="d-flex mx-auto">Registro de Informacion</h6>
    <v-row justify="center">
      <registrofincas  />
    </v-row>
  </div>
</template>

<script>
import FincasService from "@/services/FincasService";
import registrofincas from "@/components/RegistroFincas";
export default {
  components: { registrofincas },
  name: "registro",
  data: () => ({
      fincas: []
  }),
  mounted() {
    
  },
  methods: {
    cargardata() {
      let fincasservice = new FincasService();
      let response = fincasservice.getFincas("fincas");
      response
        .then((result) => {
          result.forEach((doc) => {
            //console.log("doc", doc.data());
            this.fincas.push(doc.data())
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

        //console.log('this.fincas', this.fincas)
    },
  },
};
</script>
