import { db } from "@/firebase";
import {
  collection,
  //setDoc,
  //doc,
  addDoc,
  getDocs,
} from "firebase/firestore";

export default class FincasService {
  getFincas(tabla) {
    try {
      return getDocs(collection(db, tabla));
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  getGreateFincas(data, tabla) {
    //await setDoc(doc(db, tabla, key), prod);
    const docRef = addDoc(collection(db, tabla), data);
    return docRef.id;
  }

  async listarFincas(tabla) {
    const querySnapshot = await getDocs(collection(db, tabla));
    let salida = [];

    querySnapshot.forEach((doc) => {
      salida.push(doc.data());
      //console.log(doc.id, " => ", doc.data());
    });

    return salida;
  }

  getGreatePreg(data, tabla) {
    //await setDoc(doc(db, tabla, key), prod);
    const docRef = addDoc(collection(db, tabla), data);
    return docRef.id;
  }
}
