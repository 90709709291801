import { fauth } from "@/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";

export default class ManageUser {
  /**
   * creacion de usuario
   */
  constructor() {
    this.colection = "user_details";
  }

  /**
   * metodo de creacion de usuarios
   * @param {*} email
   * @param {*} password
   * @param {*} cedula
   * @param {*} datos
   * @returns
   */
  async Creacion(email, password, cedula, datos) {
    const auth = fauth;
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //console.log(`userCredential.user`, userCredential.user.uid);
        datos.uid = userCredential.user.uid;
        setDoc(doc(db, this.colection, userCredential.user.uid), datos);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(`errorCode`, errorCode);
        //const errorMessage = error.message;
      });

    //await setDoc(doc(db, this.colection, user_uid), datos);
    return true;
  }
  /**
   *  autenticacion de usuario
   * @param {*} email
   * @param {*} password
   * @returns
   */
  async Login(email, password) {
    const auth = fauth;
    return signInWithEmailAndPassword(auth, email, password);
  }

  /**
   * obtener datos de usuario por medio de la cedula
   * @param {*} cedula
   * @returns
   */
  async getDetalleLogin(cedula) {
    //const q = query(collection(db, "user_details"),where("email", "==", email));
    const auth = fauth;
    const user = auth.currentUser;
    console.log(`user`, user);
    const docRef = doc(db, "user_details", cedula);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  }
  /**
   * Terminar la sesion de usuario
   * @returns
   */
  async Logout() {
    const auth = fauth;
    return signOut(auth);
  }
  /**
   * Lista los ururios en la coleccion
   * @returns
   */
  async listaUsuarios() {
    const q = query(
      collection(db, this.colection),
      where("activo", "==", true)
    );

    /* const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });    */
    return await getDocs(q);
  }

  /**
   * Actualizar usuario
   * @param {*} cedula
   * @param {*} objeto
   */
  async actualizar(cedula, objeto) {
    const usuarioRef = doc(db, this.colection, cedula);
    await updateDoc(usuarioRef, objeto);
  }

  /**
   * eliminar usuario
   * @param {*} cedula
   * @param {*} objeto
   */
  async eliminar(cedula) {
    const usuarioRef = doc(db, this.colection, cedula);
    await updateDoc(usuarioRef, { activo: false });
  }
}
