import axios from "axios";
import { db } from "@/firebase";
import {
  collection,
  setDoc,
  doc,
  query,
  getDocs,
  where,
} from "firebase/firestore";

export default class ProductService {
  getProductsSmall() {
    axios.get("data/products-small.json").then((res) => res.data.data);
  }

  getProducts(tabla) {
    try {
      //const q = query(collection(db, tabla), where("key", "!=", ""));
      return getDocs(collection(db, tabla));
      //const querySnapshot = await getDocs(q).then((res) => res.data);
      //return getDocs(q);
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  async getCrearProd(prod, key, tabla) {
    await setDoc(doc(db, tabla, key), prod);
    return true;
  }

  async getSingleProduct(tabla, key) {
    const q = query(collection(db, tabla), where("key", "==", key));
    return await getDocs(q);
  }

  async listarProd(tabla) {
    const querySnapshot = await getDocs(collection(db, tabla));
    let salida = [];

    querySnapshot.forEach((doc) => {
      salida.push(doc.data());
      //console.log(doc.id, " => ", doc.data());
    });

    return salida;
  }
}
