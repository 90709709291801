import { db } from "@/firebase";
import {
  collection,
  //setDoc,
  //doc,
  addDoc,
  query,
  getDocs,
  where,
} from "firebase/firestore";

export default class PlagasService {
  getPlagas(tabla) {
    try {
      const q = query(collection(db, tabla), where("estado", "==", "A"));
      //return getDocs(collection(db, tabla));
      return getDocs(q);
      //const querySnapshot = await getDocs(q).then((res) => res.data);
      //return getDocs(q);
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  getCrearPlagas(data, tabla) {
    //await setDoc(doc(db, tabla, key), prod);
    const docRef = addDoc(collection(db, tabla), data);
    return docRef.id;
  }
}
