<template>
  <v-container>
    <v-row v-if="finca_selected != null">
      <v-col cols="12" sm="6">
        <v-textarea
          class="mx-2"
          label="Utiliza usted los productos de Syngenta, cuales?"
          rows="1"
          v-model="pregunta1"
          prepend-icon="mdi-comment"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-icon="mdi-comment"
          class="mx-2"
          label="Combina los productos de syn genta con otros productos?"
          v-model="pregunta2"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          prepend-inner-icon="mdi-comment"
          class="mx-2"
          label="Tiene en cuenta la información suministrada en la etiqueta para realizar la aplicación?"
          v-model="pregunta3"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-outer-icon="mdi-comment"
          class="mx-2"
          label="Cree que podríamos tener un producto diferente para controlar enfermedades o males as en su cultivo?"
          v-model="pregunta4"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-outer-icon="mdi-comment"
          class="mx-2"
          label="Siente que la información suministrada en el. Envase es clara?"
          v-model="pregunta5"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-outer-icon="mdi-comment"
          class="mx-2"
          label="Sabe usted que hacer con el envase vacío?"
          v-model="pregunta6"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-outer-icon="mdi-comment"
          class="mx-2"
          label="Considera que podemos hacer más seguimiento a su finca a través es de otras herramientas"
          v-model="pregunta7"
          rows="1"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          append-outer-icon="mdi-comment"
          class="mx-2"
          label="Ha pensado en cultivar otro producto."
          v-model="pregunta8"
          rows="1"
        ></v-textarea>
      </v-col>

      <v-btn color="success" small class="mr-4" @click="guarda_pregunta">
        Guardar preguntas
      </v-btn>
    </v-row>

   <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
     No ha seleccionado una Finca

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
        
  </v-container>
</template>

<script>
import FincasService from "@/services/FincasService";
export default {
  props: {
    finca_selected: null,
  },
  data() {
    return {
      timeout: 2000,
      snackbar: false,
      pregunta1: null,
      pregunta2: null,
      pregunta3: null,
      pregunta4: null,
      pregunta5: null,
      pregunta6: null,
      pregunta7: null,
      pregunta8: null,
      obj_finca: {
        pregunta1: null,
        pregunta2: null,
        pregunta3: null,
        pregunta4: null,
        pregunta5: null,
        pregunta6: null,
        pregunta7: null,
        pregunta8: null,        
        nombre_finca: null          
      }
    };
  },
  methods: {
    guarda_pregunta() {
        if(this.finca_selected == null){
            this.snackbar = true
        }
      this.$Progress.start();
      this.obj_finca.pregunta1 = this.pregunta1
      this.obj_finca.pregunta2 = this.pregunta2
      this.obj_finca.pregunta3 = this.pregunta3
      this.obj_finca.pregunta4 = this.pregunta4
      this.obj_finca.pregunta5 = this.pregunta5
      this.obj_finca.pregunta6 = this.pregunta6
      this.obj_finca.pregunta7 = this.pregunta7
      this.obj_finca.pregunta8 = this.pregunta8
      this.obj_finca.nombre_finca = this.finca_selected


      let fincasservice = new FincasService();
      fincasservice.getGreatePreg(this.obj_finca, "preguntas");
      //console.log('tih.obj_finca', this.obj_finca)
      this.$Progress.finish();
      this.$router.push({ name: "Home" });
      
    },
  },
};
</script>

<style lang="scss" scoped></style>
