<template>
  <div>
    <span class="text-lg-h6">Administracion de usuarios</span>
    <crudusuario/>
  </div>
</template>

<script>
import Crudusuario from '@/components/crudusuario.vue';

export default {
  components:{Crudusuario}
};
</script>

<style lang="
Crudusuarioscss" scoped></style>
